/* stylelint-disable selector-class-pattern */
.cardListModuleWrap {
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardListModule {
  max-width: 1120px;
  width: 100%;
}

.title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  color: var(--primaryText);
}

.subTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 16px;
  text-align: center;
  color: var(--secondaryText);
}

.cardList {
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
}

.cardItem {
  border-radius: 16px;
  border: 2px solid var(--border);
  width: 100%;
  overflow: hidden;
  max-width: 728px;
  margin: auto;
}

.cardDesc {
  padding: 32px 32px 24px;
}

.cardItemTitle {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px; 
  color: var(--primaryText);
}

.cardItemSubTitle {
  font-size: 16px;
  line-height: 24px; 
  margin-top: 8px;
  color: var(--secondaryText);
}

.cardImgContainer {
  display: flex;
  height: 242px;
  overflow: hidden;
}

.cardImg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 21px 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: 100% !important;
  }
}

@media screen and (max-width: 991px) {
  .cardList {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
    margin-top: 40px;
  }
  .cardItemSubTitle {
    font-size: 14px;
    line-height: 22px; 
  }
  .cardImgContainer {
    height: 212px;
  }
  .title {
    font-size: 30px;
    line-height: 38px;
  }
  .subTitle {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (min-width: 580px) and (max-width: 991px) {
  .cardImg {
    padding: 0;
    img {
      width: 438px;
      height: 200px !important;
    }
  }
}

@media screen and (max-width: 579px) {
  .cardImg {
    padding: 0;
    img {
      width: 294px;
      height: 134px !important;
    }
  }
}

